<template>
    <div>
        <div class="firm-panel" v-if="isShow">
            <h3 class="g-title">团队成员</h3>

            <ul
                class="team-items"
                v-if="value.attestation_info && value.attestation_info.personal && value.attestation_info.personal.length>0"
            >
                <li
                    class="item"
                    v-for="(item,index) in value.attestation_info.personal"
                    :key="index"
                >
                    <div class="avatar">
                        <img :src="util.reImg(item.avatar)" />
                    </div>
                    <div class="team-cont">
                        <h4 class="name">
                            {{item.name}}
                            <i
                                :class="{'jk-icon-female': item.sex==2, 'jk-icon-male': item.sex==1 }"
                            ></i>
                        </h4>
                        <div class="tips">
                            <span>
                                年龄：
                                <em>{{item.age}}</em>
                            </span>
                            <span>
                                学历：
                                <em>{{item.education}}</em>
                            </span>
                            <span>
                                专业：
                                <em>{{item.profession}}</em>
                            </span>
                            <span>
                                职称：
                                <em>{{item.professional_qualifications}}</em>
                            </span>
                        </div>
                        <section class="team-honor">
                            <article
                                class="item"
                                v-for="(it,i) of item.certificate"
                                :key="i"
                                v-if="i<2"
                            >
                                <span class="icon">
                                    <img :src="util.reImg(it.image)" />
                                </span>
                                <div class="team-txt">
                                    <h4 class="honor-name">{{it.title}}</h4>
                                    <p class="number">{{it.code}}</p>
                                </div>
                            </article>
                            <a href="javascript:void(0)" class="more" @click="gomore(item)">
                                <span>
                                    查看
                                    <br />更多
                                </span>
                                <i class="jk-icon-right"></i>
                            </a>
                        </section>
                    </div>
                </li>
            </ul>

            <p
                v-else
                style="width:100%;text-align:center;height:300px;line-height:300px;border:1px solid #e6e6e6;font-size:14px;color:#999;"
            >暂无数据</p>
        </div>
        <div  class="firm-panel" v-else>
            <h3 class="g-title">团队成员</h3>
            <div
                style="width:100%;text-align:center;height:300px;border:1px solid #e6e6e6;background-color: #9f9b9a"
            >
            <p style="padding-top:50px;"><i style="font-size:40px;color: #f3bc66;" class="el-icon-warning-outline"></i></p>
            <p style="padding-top: 5px;font-size: large;font-weight: bolder;">仅入驻的工程极客，或实名认证后的雇主可以查看</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "teamIndex",
    props: ['value'],
    data() {
        return {
            isShow : false
        };
    },
    methods: {
        gomore(item) {
            sessionStorage.setItem('team_detail_data', JSON.stringify(item));
            this.$router.push('/firm/teamdetail');
        }
    },
    created() {
        let UserInfos = JSON.parse(localStorage.getItem('vuex'));
        if (UserInfos.userInfo.attestation && UserInfos.userInfo.attestation.is_pass == 1){
            this.isShow = true;
        }
        
    }
};
</script>





